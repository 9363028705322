// Storing these in a separate file because they should be available to the runtime, instead of just TypeScript.
// I haven't figured out how to auto-import this. Needs a bit more research.

export enum VacancyStatus {
  Draft = 'draft',
  Published = 'published',
  Expired = 'expired',
  Filled = 'filled',
  Archived = 'archived',
}

export enum VacancyType {
  Volunteering = 'volunteering',
  Workshop = 'workshop',
  P2p = 'p2p',
  Activity = 'activity',
  Event = 'event',
}

export enum Frequency {
  Once = 'once',
  Sometimes = 'sometimes',
  Regularly = 'regularly',
}

export enum TimeOfDay {
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening',
}

export enum LocationOption {
  Address = 'address',
  Flexible = 'flexible_location',
  Home = 'from_home',
}

export enum ApplicationStatus {
  Accepted = 'accepted',
  Rejected = 'rejected',
}

export enum PageLayout {
  Default = 'default',
  SideColumn = 'side_column',
}

// The backend doesn't have a Volunteer role. No role means Volunteer. Also see getRole.ts.
export enum OrganizationRole {
  Admin = 'admin',
  Volunteer = 'volunteer',
}
